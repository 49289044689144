<template>
  <div>
    <hotkeys :shortcuts="['S']" @triggered="onSave"/>
    <page-header name="Template de email">
      <b-button
        variant="outline-secondary"
        class="mr-1"
        @click="returnToList"
      >
        <FeatherIcon icon="ArrowLeftIcon"/>
        &nbsp;<span class="text-nowrap">Voltar</span>
      </b-button>
      <b-button
        variant="primary"
        @click="onSave"
      >
        <FeatherIcon icon="PlusIcon"/>
        &nbsp;<span class="text-nowrap">Salvar</span>
      </b-button>
    </page-header>

    <b-card>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-row>
          <b-col sm="12" md="8">
            <b-row>
              <b-col sm="12">
                <i2-form-input v-model="subject" name="subject" label="Assunto" rules="required"/>
              </b-col>
              <b-col sm="12">

                <div @focusin.stop>
                  <editor
                    key="editorEmailTemplate"
                    ref="editor"
                    v-model="content"
                    :inline="false"
                    height="500"
                    api-key="c453otjqlyz6b2o94pf6in1b9cxqnu1czwvbt33ywx6kedex"
                    :init="editorInit"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <h2>Variáveis</h2>
            <b-list-group>
              <b-list-group-item v-for="variable of variables" :key="variable" href="#" class="flex-column align-items-start" @click="copyToClipboard(variable.name)">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    {{<span>{{ variable.name }}</span>}}
                  </h5>
                </div>
                <small>{{ variable.description }}</small>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line

import Editor from '@tinymce/tinymce-vue'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Hotkeys from '@/components/i2/content/HotKeys.vue'

export default {
  name: 'EmailEditor',
  components: {
    Hotkeys,
    editor: Editor,
    BListGroup,
    BListGroupItem,
    ValidationObserver,
  },
  data() {
    return {
      inline: true,
      editorInit: {},
      content: '',
      subject: '',
      variables: [],
    }
  },
  created() {
    let toolBar = 'undo redo | bold italic underline strikethrough | formatselect fontselect | alignleft aligncenter '
    toolBar += 'alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor | table'
    toolBar += ' removeformat | pagebreak | charmap emoticons | fullscreen '
    toolBar += 'preview  print | image media link anchor code'
    this.editorInit = {
      plugins: ['preview importcss  searchreplace autolink code',
        'autosave  directionality  fullscreen image link media',
        'table hr pagebreak nonbreaking anchor toc advlist lists wordcount ',
        'imagetools textpattern noneditable help ',
      ],
      extended_valid_elements: 'iframe',
      menubar: false,
      height: 400,
      language: 'pt_BR',
      content_style: '.alignright {float: right; margin: 0.5em 0 0.5em 1em;} .alignleft {float: left;margin: 0.5em 1em 0.5em 0;}',
      toolbar: toolBar,
      image_advtab: true,
    }
    this.getItem(this.$route.params.uuid)
  },

  methods: {
    async onSave() {
      const validate = await this.$refs.refFormObserver.validate()
      if (!validate) {
        this.$notify.error('Verifique os campos em vermelho.')
        return
      }
      const data = {
        body: this.content,
        subject: this.subject,
      }
      const response = await this.$http.put(`email-templates/${this.$route.params.uuid}`, data)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      this.$notify.success('Template salvo com sucesso')
    },

    async getItem(uuid) {
      const response = await this.$http.get(`email-templates/${uuid}`)
      this.variables = response.variables
      this.content = response.body
      this.subject = response.subject
    },
    returnToList() {
      this.$router.push({ name: 'mail.list' })
    },
    copyToClipboard(item) {
      navigator.clipboard.writeText(`{{${item}}}`)
    },
  },
}
</script>
<style scoped>

</style>
